:root {
  --primary-color: #007aff;
  --primary-active-color: #00448c;
  --text-color: #0a0a0a;
  --background-accent: #ecf2f8;
  --background: #ffffff;
  --border: #e2e2e2;
  --input-background: #ffffff;
  --text-muted: #aaaaaa;
  --text-color-inverted: #ffffff;
  --received-message-background: #f1f1f1;
  --success: #2ecc71;
  --danger: #c0392b;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #1f5dfd;
    --primary-active-color: #7da2ff;
    --input-background: #1e1c26;
    --text-color: #dee4ea;
    --text-muted: #6a6d88;
    --text-color-inverted: var(--text-color);
    --background-accent: #1b1926;
    --background: #252331;
    --border: #343145;
    --received-message-background: #343145;
    --success: #03dac6;
    --danger: #cf6679;
  }
}

:root,
html {
  font-size: 16px;
}

html {
  margin: 0;
  padding: 0;
}

html,
body {
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;

  width: 100vw;
  height: 100vh;

  overflow: hidden;

  padding-bottom: env(keyboard-inset-height);
}

*,
a,
li,
ul,
div {
  box-sizing: border-box;
}

input {
  background-color: var(--input-background);
  color: var(--text-color);
}

body,
button,
input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

button {
  background: transparent;
  outline: none;
  border: 1px solid var(--border);
  color: var(--text-color);
  border-radius: 3px;
  padding: 0.3rem 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #f1f1f1;
}

p,
li,
span {
  word-wrap: break-word;
}

#root {
  background-color: var(--background-accent);
  color: var(--text-color);
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;

  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
}

aside {
  flex-shrink: 0;
  width: 400px;
  height: 100%;
  border-right: 1px solid var(--border);
  padding: 1rem;

  display: flex;
  flex-direction: column;
}

main {
  padding: 1rem;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

aside * {
  margin: 0;
  padding: 0;
}

aside h2 {
  font-weight: 500;
  font-size: 1.5rem;
}

.contacts * {
  margin: 0;
  padding: 0;
}

.contacts {
  list-style-type: none;
  margin-bottom: 1rem;
  padding: 0;
}

.contacts li a {
  background-color: var(--background);
  border-radius: 6px;
  margin-bottom: 0.25rem;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: block;
  border: 2px solid transparent;
  text-decoration: none;
  color: var(--text-color);
}

.contacts li.open a {
  border: 2px solid var(--primary-color);
}

.contacts span {
  color: var(--text-muted);
}

.contacts li a:hover {
  border: 2px solid var(--primary-color);
}

*::placeholder {
  /* modern browser */
  color: var(--text-muted);
}

.contacts h3 {
  font-size: 1rem;
  font-weight: 400;
}

.contacts h3.unread {
  font-weight: 600;
}

.contacts .connected {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  margin-right: 0.132rem;
  display: inline-block;
  background-color: var(--success);
}

.contacts .disconnected {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  margin-right: 0.132rem;
  display: inline-block;
  background-color: #dcdcdc;
}

.contacts .unread .contacts span {
  color: var(--border);
}

.btn {
  background-color: var(--background);
  padding: 0.25rem 0.5rem;
}

.btn-invisible {
  background: transparent;
  border: none;
}

.btn-invisible:hover,
.btn-invisible:active,
.btn-invisible:focus {
  background-color: transparent;
  color: var(--primary-active-color);
}

.version {
  padding: 0.5rem 0;
}

.btn-text {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: var(--text-muted);
  padding: 0.5rem 1rem;
  text-decoration: underline;
  cursor: pointer;
  border-radius: 4px;

  padding-left: 0;
  text-align: left;
}

.text-muted {
  color: var(--text-muted);
}

.btn-text-danger {
  color: var(--danger);
}

.btn-text:hover {
  background-color: var(--background);
  color: var(--text-color);
}

hr {
  border: none;
  border-bottom: 1px solid var(--border);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.chat-box {
  width: 100%;
  height: 100%;

  background-color: var(--background);
  border-radius: 6px;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-status {
  color: var(--text-muted);
}

.contact-status.online {
  color: var(--success);
  font-weight: 500;
}

.top-menu .contact-name {
  text-align: center;
}

.top-menu {
  min-height: 48px;
}

.top-menu h2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

.top-menu button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.top-menu button svg {
  width: 1rem;
  height: 1rem;
  color: var(--primary-color);
}

.messages-box {
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-right: 1.75rem;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  overscroll-behavior: contain;
}

.messages-box p {
  margin: 0;
}

.messages-box > div {
  margin-bottom: 0.25rem;
}

.messages-box .received {
  width: 100%;
  display: flex;
  justify-content: start;
}

.messages-box .received > div {
  background-color: var(--received-message-background);
  padding: 0.5rem;
  border-radius: 6px;
  max-width: 70%;
  position: relative;
}

.messages-box .received a {
  color: var(--text-color);
}

.messages-box .sent {
  width: 100%;
  display: flex;
  justify-content: end;
}

.messages-box .sent > div {
  background-color: var(--primary-color);
  padding: 0.5rem;
  border-radius: 6px;
  max-width: 70%;
  position: relative;
  color: var(--text-color-inverted);
}

.messages-box .sent a {
  color: var(--text-color-inverted);
}

.delivered {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background-color: var(--primary-color);
  position: absolute;
}

.not-delivered {
  background-color: #dcdcdc;
}

.read {
  background-color: var(--success);
}

.messages-box .sent .delivered {
  bottom: 0;
  right: -0.75rem;
}

.messages-box .received .delivered {
  display: none;
}

.compose-box {
  border-top: 1px solid var(--border);
  width: 100%;
  padding: 1rem;

  display: flex;
  gap: 1rem;
}

.compose-box input {
  width: 100%;
  resize: none;
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 0.5rem;
  outline: none;
  font-size: 1rem;
}

.compose-box input:focus {
  border-color: var(--border);
  outline: none;
}

.compose-box button {
  border-radius: 6px;
  border-color: var(--border);
}

.generic-form label,
.generic-form input,
.generic-form button {
  display: block;
  margin-bottom: 0.25rem;
}

.generic-form input,
.generic-form button {
  width: 100%;
}

.generic-form input {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid var(--border);
}

.generic-form button {
  background-color: var(--background);
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
  border-color: var(--border);
  font-size: 1rem;
}

aside h2 {
  text-overflow: ellipsis;
  overflow: hidden;
}

aside a {
  text-decoration: none;
  color: var(--text-color);
}

.utility {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conn-status div {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
}

.conn-status .connected {
  background-color: var(--success);
}

.conn-status .disconnected {
  background-color: var(--danger);
}

.side-content {
  height: 100%;
  overflow-y: auto;
}

.side-footer {
  padding-top: 1rem;
}

.radio-item {
  display: block;
}

.radio-item input {
  width: fit-content;
  margin-right: 0.25rem;
  display: inline-block;
}

.radio-item label {
  display: inline-block;
}

.center-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-content-inner {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  width: 100%;
  max-width: 480px;
}

.text-button:hover {
  text-decoration: underline;
}

.close-menu-button {
  color: var(--text-muted);
  padding: 0.5rem;
  border: none;
}

.close-menu-button svg {
  width: 30px;
  height: 30px;
}

.close-menu-button:hover,
.close-menu-button:active,
.close-menu-button:focus {
  background-color: var(--background);
}

#show-menu-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--background-accent);
  padding: 1rem;
  z-index: 1;

  display: flex;

  align-items: center;
  gap: 1rem;
  justify-content: center;
}

aside,
aside > div {
  overflow: hidden;
  flex-shrink: none;
}

aside > div {
  flex-wrap: wrap;
}

aside h2 {
  display: block;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  word-wrap: normal;
  word-break: break-all;
}

@media (max-width: 800px) {
  #root {
    display: block;
  }

  aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: var(--background-accent);
    z-index: 10;

    display: none;
  }

  /* aside {
    display: none;
  }

  aside.force-show {
    display: flex;
  } */
}

@media (min-width: 801px) {
  #show-menu-button {
    display: none;
  }

  .close-menu-button {
    display: none;
  }
}

.startup-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.startup-selection > div {
  background: #ffffff;
  padding: 1rem;
  border-radius: 8px;

  width: 100%;
  max-width: 600px;
}

.startup-selection h2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

.startup-selection p {
  color: var(--text-muted);
}

pre {
  margin: 0;
}

.unstyled-list {
  list-style: none;
  padding: 0;
}

.unstyled-list li {
  background: var(--background);
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}
